import axios from 'axios';
export default class GeneralUserService {

    // async getFreeBoardList(title,country_id,category_id,post_date) {
    //     return await axios
    //         .post(`asnhist-dev/api/v1/admin/board`, {
    //             // title: title,
    //             title: title,
    //             country_id: country_id,
    //             category_id: category_id,
    //             post_date: post_date,
    //         })
    //         .then((res) => res);
    // }
    async getGeneralList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/user/general', data);
    }

    async getCountrydropdown() {
        return await axios
            .get(`asnhist-dev/api/v1/admin/country`, {
                
            })
            .then((res) => res);
    }


    // async getBoardCategorydropdown() {
    //     return await axios
    //         .get(`asnhist-dev/api/v1/admin/board-category/all`, {
                
    //         })
    //         .then((res) => res);
    // }

    async getBoardCategorydropdown(country_id) {
            return await axios.post('asnhist-dev/api/v1/admin/board-category/all', { country_id: country_id })
            .then((res) => res);
        
    }

    

    async viewFreeBoard(ids) {
        return await axios.post(`asnhist-dev/api/v1/admin/free-board`, { id: ids }).then((res) => res);
    }

    
}